import { useEffect, useState } from "react";
import supabase from "./supabase";
import "./style.css";

const initialFacts = [
  {
    id: 1,
    text: "San Moteur vinner Elitloppet",
    source: "https://opensource.fb.com/",
    bookie: "https://atg.com",
    sport: "trav",
    upVotes: 1,
    neutralVotes: 2,
    downVotes: 10,
    createdIn: 2021,
  },
  {
    id: 2,
    text: "HV71 vinner SM-guld",
    source: "https://example.com/",
    bookie: "https://unibet.com",
    sport: "hockey",
    upVotes: 15,
    neutralVotes: 2,
    downVotes: 10,
    createdIn: 2021,
  },
  {
    id: 3,
    text: "Newcastle vinner Premier League",
    source: "https://test.com/",
    bookie: "https://pinnacle.com",
    sport: "fotboll",
    upVotes: 11,
    neutralVotes: 2,
    downVotes: 1,
    createdIn: 2021,
  },
];

// function Counter() {
//   const [count, setCount] = useState(0);

//   return (
//     <div>
//       <span style={{ fontsize: "40px" }} n>
//         {count}
//       </span>
//       <button className="btn btn-large" onClick={() => setCount((c) => c + 1)}>
//         +1
//       </button>
//     </div>
//   );
// }

function App() {
  const [showForm, setShowForm] = useState(false);
  const [bets, setBets] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [currentSport, setCurrentSport] = useState("all");

  useEffect(
    function () {
      async function getBets() {
        let query = supabase.from("bets").select("*");

        if (currentSport !== "all") query = query.eq("sport", currentSport);

        const { data: bets, error } = await query

          .order("created_at", { ascending: false })
          .limit(1000);
        setBets(bets);

        if (!error) setBets(bets);
        else alert("Det är något som strular. Prova senare");
      }
      getBets();
    },
    [currentSport]
  );

  return (
    <>
      <Header showForm={showForm} setShowForm={setShowForm} />

      {showForm ? (
        <NewBetForm setBets={setBets} setShowForm={setShowForm} />
      ) : null}

      <main className="main">
        <Filter setCurrentSport={setCurrentSport} />
        <BetList bets={bets} setBets={setBets} />
      </main>
    </>
  );
}

function Header({ showForm, setShowForm }) {
  const appTitle = "TimeToBet";
  return (
    <header className="header">
      <div className="logo">
        <img src="ttb-logo.png" alt="TimeToBet logo" />
        <h1>{appTitle}</h1>
      </div>
      <button
        className="btn btn-open hidden"
        onClick={() => setShowForm((show) => !show)}
      >
        {showForm ? "Stäng" : "Reka ett spel!"}
      </button>
    </header>
  );
}
const CATEGORIES = [
  { name: "fotboll", color: "#1e293b" },
  { name: "golf", color: "#1e293b" },
  { name: "hockey", color: "#1e293b" },
  { name: "mma", color: "#1e293b" },
  { name: "tennis", color: "#1e293b" },
  { name: "trav", color: "#1e293b" },
  { name: "övrigt", color: "#1e293b" },
];

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

function NewBetForm({ setBets, setShowForm }) {
  const [text, setText] = useState("");
  const [source, setSource] = useState("");
  const [bookie, setBookie] = useState("");
  const [sport, setSport] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  async function handleSubmit(e) {
    //1. Prevent browser reload
    e.preventDefault();
    console.log(text, source, bookie, sport);

    // 2. Check if Data is valid. If so, create a new bet
    if (text && isValidHttpUrl(source) && isValidHttpUrl(bookie) && sport) {
      // 3. Create a new bet object
      // const newBet = {
      //   id: Math.round(Math.random() * 1000000000),
      //   text,
      //   source,
      //   bookie,
      //   sport,
      //   upVotes: 0,
      //   neutralVotes: 0,
      //   downVotes: 0,
      //   createdIn: new Date().getFullYear(),
      // };

      //3.1 upload fact to supabase and recieve factobj
      setIsUploading(true);
      const { data: newBet, error } = await supabase
        .from("bets")
        .insert([{ text, source, bookie, sport }])
        .select();
      setIsUploading(false);
      // 4. Add new bet to the state
      if (!error) setBets((bets) => [newBet[0], ...bets]);

      // 5. Reset the input fields back to empty
      setText("");
      setSource("");
      setBookie("");
      setSport("");

      // 6. close the entire form
      setShowForm(false);
    }
  }

  return (
    <form className="fact-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Reka ett spel..."
        value={text}
        onChange={(e) => setText(e.target.value)}
        disabled={isUploading}
      />
      <input
        value={source}
        type="text"
        placeholder="Läs hela reken"
        onChange={(e) => setSource(e.target.value)}
      />
      <input
        value={bookie}
        type="text"
        placeholder="Var finns oddset?"
        onChange={(e) => setBookie(e.target.value)}
      />
      <select
        value={sport}
        onChange={(e) => setSport(e.target.value)}
        disabled={isUploading}
      >
        <option value="">SPORT:</option>
        {CATEGORIES.map((cat) => (
          <option key={cat.name} value={cat.name}>
            {cat.name.toUpperCase()}
          </option>
        ))}
      </select>
      <button className="btn" disabled={isUploading}>
        Reka
      </button>
    </form>
  );
}

function Filter({ setCurrentSport }) {
  return (
    <>
      <p className="text">
        TimeToBet gör det enkelt att hitta och rygga de bästa speltipsen. Vi
        samlar rekar och idéer från starka tipsters så att du kan spara tid och
        vinna mer!
      </p>
      <p className="text">
        Vi ger även våra läsare möjligheten att rösta på rekarna så att du
        enkelt ser vilka spel som är heta för dagen.
      </p>

      <ul className="text">
        <li>🟢 Total bomb</li>
        <li>🟡 Lira om du har feeling</li>
        <li>🔴 Kan bli torsk...</li>
      </ul>

      <aside className="sport-filter">
        <div className="specific-sport">
          <button
            className="btn btn-sport"
            onClick={() => setCurrentSport("all")}
          >
            Alla sporter
          </button>
          {CATEGORIES.map((cat) => (
            <button
              key={cat.name}
              className="btn btn-sport"
              onClick={() => setCurrentSport(cat.name)}
            >
              {cat.name}
            </button>
          ))}
        </div>
      </aside>
    </>
  );
}

function BetList({ bets, setBets }) {
  // temporary

  return (
    <section className="bet-section">
      <ul className="bet-list">
        {bets.map((bet) => (
          <Bet key={bet.id} bet={bet} setBets={setBets} />
        ))}
      </ul>
      <p>Det finns {bets.length} speltips i databasen!</p>
    </section>
  );
}

function Bet({ bet, setBets }) {
  const [isUpdating, setIsUpdating] = useState(false);

  async function handleVote(columnName) {
    setIsUpdating(true);
    const { data: updatedBet, error } = await supabase
      .from("bets")
      .update({ [columnName]: bet[columnName] + 1 })
      .eq("id", bet.id)
      .select();
    setIsUpdating(false);
    console.log(updatedBet);
    if (!error)
      setBets((bets) => bets.map((b) => (b.id === bet.id ? updatedBet[0] : b)));
  }
  return (
    <li className="fact">
      <p>
        {bet.text}
        <a className="bookie" href={bet.bookie} target="_blank">
          (Rygga)
        </a>
        <a className="source" href={bet.source} target="_blank">
          (Tipster)
        </a>
      </p>

      <div className="vote-buttons">
        <button onClick={() => handleVote("upVotes")} disabled={isUpdating}>
          🟢 {bet.upVotes}
        </button>
        <button
          onClick={() => handleVote("neutralVotes")}
          disabled={isUpdating}
        >
          🟡 {bet.neutralVotes}
        </button>
        <button onClick={() => handleVote("downVotes")} disabled={isUpdating}>
          🔴 {bet.downVotes}
        </button>
      </div>
    </li>
  );
}

export default App;
